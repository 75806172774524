(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

// if (document.getElementById("calendar")) {

//   document.addEventListener("DOMContentLoaded", function () {
//     var calendarEl = document.getElementById("calendar");

//     var calendar = new FullCalendar.Calendar(calendarEl, {
//       initialView: "timeGridWeek",
//       firstDay: "1",
//       slotLabelFormat: {
//         hour: "numeric",
//         minute: "2-digit",
//         omitZeroMinute: false,
//         meridiem: "false ",
//       },
//       slotMinTime: "09:00:00",
//       slotMaxTime: "21:00:00",
//       locale: "fr",
//       contentHeight: "auto",
//       headerToolbar: false,

//       events: [
//         {
//           className: "bg-color1-500",
//           title: "Cours particluliers",
//           rrule: {
//             dtstart: "2022-02-15T14:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-500",
//           title: "Poneys G1/2",
//           rrule: {
//             dtstart: "2022-02-16T14:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-500",
//           title: "Poneys G3/4",
//           rrule: {
//             dtstart: "2022-02-16T15:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-300",
//           title: "Shetlands débutants",
//           rrule: {
//             dtstart: "2022-02-16T15:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-300",
//           title: "Shetlands G1/2",
//           rrule: {
//             dtstart: "2022-02-16T16:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-300",
//           title: "Baby poney",
//           rrule: {
//             dtstart: "2022-02-16T17:00:00",
//             freq: "weekly",
//           },
//           duration: "00:30",
//         },
//         {
//           className: "bg-color2-500",
//           title: "Poneys G4/5",
//           rrule: {
//             dtstart: "2022-02-16T18:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-700",
//           title: "Chevaux G6/7",
//           rrule: {
//             dtstart: "2022-02-16T19:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-500",
//           title: "Cours particluliers",
//           rrule: {
//             dtstart: "2022-02-17T18:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-700",
//           title: "Adultes loisirs",
//           rrule: {
//             dtstart: "2022-02-17T19:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-700",
//           title: "Pony Games",
//           rrule: {
//             dtstart: "2022-02-17T19:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-500",
//           title: "Cours particluliers",
//           rrule: {
//             dtstart: "2022-02-17T20:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-500",
//           title: "Cours particluliers",
//           rrule: {
//             dtstart: "2022-02-18T14:30:00",
//             freq: "weekly",
//           },
//           duration: "02:30",
//         },
//         {
//           className: "bg-color2-300",
//           title: "Compétition 1ère année",
//           rrule: {
//             dtstart: "2022-02-18T17:30:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-300",
//           title: "Compétition 2ème année",
//           rrule: {
//             dtstart: "2022-02-18T18:30:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-300",
//           title: "Compétition 3ème année",
//           rrule: {
//             dtstart: "2022-02-18T19:30:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-700",
//           title: "Adultes loisirs",
//           rrule: {
//             dtstart: "2022-02-19T09:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },

//         {
//           className: "bg-color2-300",
//           title: " Compétition CCE",
//           rrule: {
//             dtstart: "2022-02-19T10:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-500",
//           title: "Cours particuliers  ",
//           rrule: {
//             dtstart: "2022-02-19T10:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-500",
//           title: "Cours particuliers ",
//           rrule: {
//             dtstart: "2022-02-19T11:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-500",
//           title: " Shetlands débutants",
//           rrule: {
//             dtstart: "2022-02-19T11:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-500",
//           title: "Poneys G3/4 ",
//           rrule: {
//             dtstart: "2022-02-19T13:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-300",
//           title: "Shetlands G1 ",
//           rrule: {
//             dtstart: "2022-02-19T14:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-500",
//           title: " Cours particuliers",
//           rrule: {
//             dtstart: "2022-02-19T15:00:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-500",
//           title: " Poneys G1/2",
//           rrule: {
//             dtstart: "2022-02-19T15:15:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color2-500",
//           title: " Poneys G3/4",
//           rrule: {
//             dtstart: "2022-02-19T16:30:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-700",
//           title: "Loisirs G5 ",
//           rrule: {
//             dtstart: "2022-02-19T17:45:00",
//             freq: "weekly",
//           },
//           duration: "01:00",
//         },
//         {
//           className: "bg-color1-700",
//           title: " Concours ou animation",
//           rrule: {
//             dtstart: "2022-02-20T09:00:00",
//             freq: "weekly",
//           },
//           duration: "09:00",
//         },
//       ],
//     });
//     calendar.render();
//   });

// }

if (document.getElementById('calendar')) {
  var calendarEl = document.getElementById('calendar');
  var calendarVal = JSON.parse(document.getElementById('calendarVal').value);
  var calendar = new FullCalendar.Calendar(calendarEl, {
    initialView: 'timeGridWeek',
    firstDay: '1',
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'false '
    },
    slotMinTime: '09:00:00',
    slotMaxTime: '22:00:00',
    locale: 'fr',
    contentHeight: 'auto',
    events: calendarVal
  });
  calendar.render();
}

},{}]},{},[1]);
